import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';

export const DataCell = styled.td`
  display: block;
  width: 100%;
  margin-bottom: 4px;
  font-size: 12px;
  vertical-align: top;

  &:first-child {
    margin-bottom: 8px;
  }

  & a {
    color: ${colors.B500};
    font-weight: ${weights.BOLD};
    text-decoration: underline;
  }

  & span {
    font-weight: ${weights.BOLD};
  }

  ${mq.gtmd`
    display: table-cell;
    width: 25%;
    margin: 0;
    padding: 24px 32px 24px 0;
    font-size: 16px;
    border-bottom: 1px solid ${colors.B100};

    &:first-child {
      margin-bottom: 0;
    }

    & span {
      display: none;
    }
  `}
`;

export const HeaderCell = styled.th`
  text-align: left;
  border-bottom: 3px solid ${colors.B100};
`;

export const ImageWrapper = styled.div`
  flex: 1 0 48px;
  position: relative;
  height: 48px;
  width: 48px;
  max-width: 48px;
  max-height: 48px;
  margin-right: 16px;
  border-radius: 24px;
  overflow: hidden;
`;

export const Info = styled.div`
  display: flex;
`;

export const SortToggle = styled.button`
  padding: 16px 0;
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${(props) => props.isActive ? weights.BOLD : weights.NORMAL};
  appearance: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  & > svg {
    display: ${(props) => props.isActive ? 'inline-block' : 'none'};
    height: 12px;
    width: 12px;
    margin-left: 6px;
    vertical-align: middle;
    transform: translateY(-2px) rotate(${(props) => props.sortOrder === 'desc' ? '180deg' : 0});
    transition: transform 150ms ease;
  }
`;

export const Table = styled.table`
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  width: 100%;

  & thead {
    display: none;

    ${mq.gtmd`
      display: table-header-group;
    `}
  }
`;

export const TableRow = styled.tr`
  display: block;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.B100};

  ${mq.gtmd`
    display: table-row;
    padding: 0;
    border-bottom: 0;
  `}
`;
