import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getLocalizedData from '@utils/localize';
import useSearchSortFilter from '@utils/searchSortFilter';
import { TranslationContext } from '@utils/useTranslations';
import Container from '@components/Container';
import EmptyState from '@components/EmptyState';
import Footer from '@components/Footer';
import Navigation, { NavSpacer } from '@components/Navigation';
import SearchSortFilter from '@components/SearchSortFilter';
import SEO from '@components/SEO';
import Space from '@components/Space';
import StaffTable from '@components/StaffTable';

function StaffPage({ banner, navItems }) {
  const rawData = useStaticQuery(graphql`
    query StaffQuery {
      allSanityStaff(sort: { fields: name, order: ASC }) {
        edges {
          node {
            _id
            email
            department {
              en
              vi
              zh_Hans
              _type
            }
            yearAppointed
            title {
              en
              vi
              zh_Hans
              _type
            }
            name
            imageExtended {
              altText {
                en
                vi
                zh_Hans
                _type
              }
              image  {
                asset {
                  fluid(
                    maxWidth: 800,
                    sizes: "(max-width: 600px) 100vw, 50vw"
                    ){
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  const staff = get(data, 'allSanityStaff.edges', []).map(({ node }) => node);
  const departments = [...new Set(staff.map((s) => s.department))]
    .filter(Boolean)
    .sort();

  const { controller, items } = useSearchSortFilter(staff, {
    initialFilter: {
      department: [],
    },
    initialSort: { key: 'name', order: 'asc' },
    searchFields: ['department', 'name', 'title'],
  });

  const translationContext = {
    vi: true,
    zh_Hans: true,
  };

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={banner} navItems={navItems} />
        <main>
          <SEO title="Faculty and Staff" />
          <NavSpacer />
          <SearchSortFilter
            controller={controller}
            filters={[
              {
                key: 'department',
                title: intl.formatMessage({ id: 'departments' }),
                items: departments.map((department) => ({
                  name: department,
                  value: department,
                })),
              },
            ]}
            search={{ title: intl.formatMessage({ id: 'facultyAndStaff.search.title' }) }}
          />
          <Container centered>
            {items.length ? (
              <StaffTable controller={controller} staff={items} />
            ) : (
              <EmptyState />
            )}
          </Container>
        </main>
        <Space sizes={['small', 'large']} />
        <Footer />
      </TranslationContext.Provider>
    </>
  );
}

export default StaffPage;
