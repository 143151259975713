import React from 'react';
import get from 'lodash/get';
import { useIntl } from '@utils/localize';

import { ChevronDown } from '@components/Icon';
import Image from '@components/Image';
import { Caption, Heading } from '@components/type';
import * as styled from './styles';

function StaffTable({ controller, staff }) {
  const intl = useIntl();

  const headerCells = [
    {
      title: intl.messages['staffTable.name'],
      key: 'name',
    },
    {
      title: intl.messages['staffTable.department'],
      key: 'department',
    },
    {
      title: '',
      key: 'yearAppointed',
    },
    {
      title: intl.messages['staffTable.email'],
      key: 'email',
    },
  ];

  return (
    <styled.Table>
      <thead>
        <tr>
          {headerCells.map((headerCell, idx) => (
            <styled.HeaderCell key={idx}>
              <styled.SortToggle
                isActive={get(controller, 'state.sort.key') === headerCell.key}
                sortOrder={get(controller, 'state.sort.order')}
                onClick={() => {
                  controller.sort({
                    key: headerCell.key,
                    order:
                      get(controller, 'state.sort.order') === 'desc'
                        ? 'asc'
                        : 'desc',
                  });
                }}
              >
                {headerCell.title}
                <ChevronDown />
              </styled.SortToggle>
            </styled.HeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {staff.map((s) => (
          <styled.TableRow key={s._id}>
            <styled.DataCell>
              <styled.Info>
                {get(s, 'imageExtended.image.asset.fluid') && (
                  <styled.ImageWrapper>
                    <Image fluid={s.imageExtended.image.asset.fluid} altText={s.name}/>
                  </styled.ImageWrapper>
                )}
                <div>
                  <Heading level={500}>{s.name}</Heading>
                  <Caption size="small">{s.title}</Caption>
                </div>
              </styled.Info>
            </styled.DataCell>
            <styled.DataCell>
              <span>
                {intl.formatMessage({ id: 'staffTable.department' })}:
              </span>{' '}
              {s.department}
            </styled.DataCell>
            <styled.DataCell>
              &nbsp;
            </styled.DataCell>
            <styled.DataCell>
              <a href={`mailto:${s.email}`}>
                <Caption sizes={['small', 'regular']}>{s.email}</Caption>
              </a>
            </styled.DataCell>
          </styled.TableRow>
        ))}
      </tbody>
    </styled.Table>
  );
}

export default StaffTable;
